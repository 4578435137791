import { StoreAction } from "../../@core/constants";

const initialState = {
  all_lead: [],
  kanban_lead: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.GET_ALL_LEAD_DATA:
      return {
        ...state,
        all_lead: action.payload,
      };
    case StoreAction.GET_ALL_KANBAN_LEAD: {
      return { ...state, kanban_lead: action.payload };
    }

    default:
      return state;
  }
}
