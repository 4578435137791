export const ApiRoutes = {
  API_HOSTNAME: process.env.REACT_APP_API_HOSTNAME,
  WEB_SOCKET_URL: process.env.REACT_APP_WEB_SOCKET_URL,

  GETNOTES: "getnotes",
  LOGIN: "organizationlogin",
  FORGOTPASSWORD: "forgotpassword",
  RESETPASSWORD: "resetpassword",
  USERSSTOREDETAILS: "loginuserwisestoredetails",
  USERANDSTOREDEATILS: "userandstoredetals",

  //STORE
  CREATESTORE: "addstore",
  COUNTSTORE: "countstore",
  GETSTRORE: "getstoreData",
  UPDATESTORE: "updatestore",
  DELETESTORE: "deletestore",
  FINDSTOREEXIST: "findstoreexist",
  GETSTOREWISEUSER: "getstoreuser",
  GETSTOREDETAILS: "getstoredetails",
  GETSTOREUSERDETAILS: "getstoreuserdetails",
  COUNTUSERRECORD: "getCountUsers",

  // CATEGORY
  INSERTCATEGORY: "insertcategory",
  GETCATEGORY: "getcategory",
  UPDATECATEGORY: "updatecategory",
  DELETECATEGORY: "deletecategory",
  GETCATEGORYPAGINATION: "getcategorypagination",
  DEACTIVECATEGORY: "deactivecategory",
  FINDCATEGORY: "findcategory",

  //STAFF
  INSERTSTAFF: "insertuserregistration",
  GETSTAFF: "getstafflist",
  GETSTAFFDATAFOROPTION: "getstaff",
  UPDATESTAFF: "updateuserdata",
  DELETEUSER: "deleteuser",
  FINDUSEREXIST: "userexist",
  GESTSTAFFDATAWITHPAGINATION: "usergetpagination",
  DEACTIVEUSER: "deactiveuser",
  GETSTOREWISESTAFF: "getstorewisestaff",

  //COLOR
  INSERTCOLOR: "insertcolors",
  GETCOLORS: "getcolors",
  UPDATECOLORS: "updatecolors",
  DELETECOLORS: "deletecolors",
  GETUSEDCOLORS: "getalreadyusedcolors",
  UPDATEPRODUCTCOLOR: "updateproductcolor",
  COLORDATA: "getcolorforproduct",
  ACTIVEDEACTIVECOLOR: "activedeactivecolor",

  // SIZE
  INSERTSIZE: "insertsize",
  GETSIZE: "getsize",
  UPDATESIZE: "updatesize",
  DELETESIZE: "deletesize",
  GETUSEDSIZE: "getalreadyusedsize",
  UPDATEPRODUCTSIZE: "updateproductsize",
  SIZEDATA: "getsizeforproduct",
  ACTIVEDEACTIVESIZE: "activedeactivesize",

  //SUBCATEGORY
  INSERTSUBCATEGORY: "insertsubcategory",
  GETSUBCATEGORY: "getsubcategory",
  UPDATESUBCATEGORY: "updatesubcategory",
  DELETESUBCATEGORY: "deletesubcategory",
  GETSUBCATEGORYPAGINATION: "getsubcategorypagination",
  DEACTIVESUBCATEGORY: "deactivesubcategory",
  FINDSUBCATEGORY: "findsubcategory",

  //SERVICE USER TYPE
  INSERTSERVICEUSERTYPE: "insertserviceusertype",
  UPDATESERVICEUSERTYPR: "updateserviceusertype",
  GETSERVICEUSERTYPE: "getserviceusertype",
  DELETESERVICEUSERTYPE: "deactiveserviceusertype",
  GETSERVICEUSERTYPEPAGINATION: "getserviceusertypepagination",

  // SERVICE USER
  INSERTSERVICEUSER: "insertserviceuser",
  GETSERVICEUSER: "getserviceuser",
  UPDATESERVICEUSER: "updateserviceuser",
  DELETESERVICEUSER: "deactiveserviceuser",
  GETSERVICEUSERS: "getserviceuser",
  GETSERVICEUSERPAGINATION: "getserviceuserpagination",

  // PRODUCTS
  INSERTPRODUCTS: "productinsert",
  GETPRODUCTDATA: "getproducts",
  DELETEPRODUCTS: "deleteproducts",
  UPDATEPRODUCTDATA: "updateproducts",
  ACTIVEDEACTIVEPRODUCTS: "activedeactiveproducts",
  DELETEPRODUCTIMAGES: "deleteproductimage",
  GETPRODUCTIMAGES: "productsimagedata",
  GETPRODUCTBARCODE: "getproductbarcode",
  DOWNLOADPRODUCTBARCODE: "downloadproductbarcode",

  // Organization Dashboard
  MONTHWISELEADS: "monthwiseleads",
  MONTHWISERETURNPRODUT: "monthwisereturnproduct",
  STOREWISEDATA: "storewisedata",

  // Store Dashboard
  COUNTSTOREWISEPRODUCTS: "countstorewiseproducts",
  COUNTSTOREWISEORDERS: "countstorewiseorders",
  COUNTSTOREWISERETURNPRODUCTS: "countstorewisereturnproducts",
  COUNTSTOREWISEPENDINGRETURNPRODUCTS: "countstorewisependingreturnproducts",
  STOREWISEMONTHLYRETURNPRODUCTS: "storewisemonthlyreturnproducts",
  STOREWISEMONTHLYLEADS: "storewisemonthlyleads",
  COUNTSTOREWISELEADS: "countstorewiseleads",
  GETTODAYORDERS: "gettodayorder",
  GETTODAYRETURNORDER: "gettodayreturnorder",
  GETTOMORROWRETURNORDER: "gettomorrowreturnorder",


  //CUSTOMER
  INSERTCUSTOMER: "insertcustomer",
  GETCUSTOMER: "getcustomer",
  GETCUSTOMERPAGINATION: "getcustomerpagination",
  UPDATECUSTOMER: "updatcustomer",
  DELETECUSTOMER: "deletecustomer",

  //LEAD
  INSERTLEAD: "insertlead",
  GETLEAD: "getlead",
  UPDATELEAD: "updatelead",
  UPDATELEADSTATUS: "leadstatusupdate",
  DELETELEAD: "deletelead",
  LEADSTATUS: "getleadstatus",
  LEADSOURCE: "getleadsource",
  CHECKCUSTOMEREXISTED: "checkcustomerexisted",
  GETLEADHISTORY: "getleadhistory",
  GETLEADFOLLOWUP: "getleadfollowup",
  LEADIMPORT: "leadimport",

  // LEAD FOLLOWUP
  ADDFOLLOWUP: "leadfollowup",

  // LEAD ASSIGN
  LEADASSIGNTO: "leadassign",

  // LEAD CONVERT TO CUSTOMER
  LEADCONVERT: "leadconvert",

  // INCOME EXPENSE

  INSERTINCOMEEXPENSE: "insertincomeexpense",
  GETINCOME: "getincome",
  GETEXPENSE: "getexpense",
  UPDATEINCOMEEXPENSE: "updateincomeexpense",
  DELETEINCOMEEXPENSE: "deleteincomeexpense",

  // WEBSETTING GET

  GETWEBSETTING: "getwebsetting",
  UPDATEWEBSETTING: "updatewebsetting",
  UPDATESTOREWEBSETTING: "updatestorewebsetting",
  GETSTOREWEBSETTING: "getstorewebsettingdetails",
  PRODUCTSDATA: "productsdata",

  // PAYMENT METHOD
  INSERTPAYMENTMETHOD: "insertpaymentmethod",
  GETPAYMENTMETHOD: "getpaymentmethod",
  UPDATEPAYMENTMETHOD: "updatepaymentmethod",
  DELETEPAYMENTMETHOD: "deletepaymentmethod",

  // Return Product
  GETRETURNPRODUCT: "getreturnproduct",
  GETRETURNPRODUCTUSERS: "getreturnproductusers",
  ADDWASHINGPRODUCT: "addwashingproduct",

  // Washing Product
  GETWASHINGPRODUCT: "getwashingproduct",
  WASHINGPRODUCTARRIVE: "washingproductarrive",

  // RENT ORDER
  ADDORDERDATA: "addorderdata",
  GETORDERSDATA: "ordersdata",
  UPDATEORDERDATA: "updateorderdata",
  RETURNORDER: "returnorder",
  DOWNLOADORDERINVOIVE: "downloadorderinvoice",
  GETPRODUCTNAMES: "getproductnames",
  CHECKAVALABILITY: "checkavailability",
  PAYMENTMETHODDATA: "getpaymentmethoddata",
  CANCELORDER: "cancelorder",
  INSERTORDERPAYMENT: "insertorderpayment",
  ORDERPAYMENTHISTORY: "orderpaymenthistorydata",
  UPDATEORDERPAYMENT: "updateorderpayment",
  PAYDEFECTFINE: "paydefectfine",
  BARCODEWISEPRODUCT: "barcodewiseproduct",
  ORDERDATILSDATA: "getorderdetails",
  RETURNSECURITYAMT: "returnsecurityamt",
  FINDSELECTEDDATEORDERFOUND: 'findselecteddateorderfound',

  // LEAD SOURCE
  INSEERTLEADSOURCE: "leadsourceinsert",
  GETLEADSOURCE: "getleadsource",
  UPDATELEADSOURCE: "updateleadsource",
  DELETELEADSOURCE: "deleteleadsource",

  // LEAD STATUS
  INSEERTLEADATTRIBUTE: "insertleadattribute",
  UPDATELEADATTRIBUTE: "updateleadattribute",
  DELETELEADATTRIBUTE: "deleteleadattribute",
  GETLEADSTATUS: "getleadstatus",

  // PROFILE
  UPDATEPROFILE: "updateprofile",

  //TEST EMAIL
  TESTEMAIL: "testemail",

  // NOTIFICATION
  GETNOTIFICATIONDATA: "getnotificationdata",
  READNOTIFICATIONDATA: "readnotificationdata",
  // REPORTS
  INCOMEREPORT: "incomereport",
  EXPENSEREPORT: "expensereport",
  PRODUCTWISEORDERCOUNT: "productwiseordercount",
  PENDINGRETURNPRODUCTREPORT: "pendingreturnproductreport",
  PENDINGWASHINGPRODUCTREPORT: "pendingwashingproductreport",

  // GLOBAL SERACH
  GETGLOBALSEARCHDATA: "getglobalsearchdata",
};
